<template>
        <div class="loader">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
            </svg>
            <p>{{ msg }}</p>
        </div>
</template>

<script>
    export default {
        name: 'LoaderElement',
        props: {
            msg: String,
        }
    }
</script>

<style scoped>
    .loader {
        height: 100%;
        text-align: center;
        font-size: 1rem;
        margin-top: 100px;
    }

    svg {
        height: 8rem;
        color: black;
        animation: turn 1s linear infinite;
    }

    @keyframes turn {
        from {
            transform: rotate(0turn);
        }
        to {
            transform: rotate(1turn);
        }
    }
</style>