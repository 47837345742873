<template>
    <div class="welcome-card">
        <h1>Welcome to Allbanimate.com !</h1>
        <p>Allbanimate is a free 2D animation software in the early phase of it's developement.</p>
        <p>Please join the <a target=”_blank” href="https://discord.gg/SADC3rMeYb">official discord server</a> if you want to give feedbacks and report bugs to help the project.</p>
        <img src="@/assets/preview.png" alt="preview">
    </div>

</template>

<script>
    export default {

        name: 'WelcomeCard',
  
        methods: {

        }
    }
</script>


<style scoped>

    img {
        /*height: 50vh;*/
        width: 50vw;
    }
    
</style>